import React, { useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { GridMenuActionLink, GridMenuCell } from '../../shared/grid/GridMenu'
import { toastSuccess } from '../../shared/toastr'
import { Grid } from '../../shared/grid/Grid'
import { GridActions, GridData, TableParams } from '../../shared/grid/Types'
import { ApiClient } from '../../shared/api/api-client'
import { MobileSidebarOpener } from '../../shared/elements/MobileSidebarOpener'

interface CompanyRow {
    id: number
    name: string
    email: string
    address: string
    type: number
}

const companyTypeToString = (companyType: number) => {
    switch (companyType) {
        case 0:
            return 'Basic'

        case 1:
            return 'Lawyer'

        case 2:
            return 'Window Maker'

        case 3:
            return 'Renter'

        case 4:
            return 'Other'
    }
}

export const TemplateCompaniesListContainer = () => {
    const { t } = useTranslation()

    const impersonate = (companyId: number) => {
        ApiClient.post<string>('/api/impersonation/RequestAccessToken', {
            companyId: companyId,
        }).then(encryptedToken => {
            window.open(
                `${process.env.REACT_APP_SERVER_LOCATION}/impersonation?encryptedToken=${encodeURIComponent(encryptedToken)}`,
                '_blank'
            )
        })
    }

    const RowComponent = ({ row, gridActions }: { row: CompanyRow; gridActions: GridActions }) => {
        return (
            <>
                <span style={{ width: '300px' }} className="table__cell table__cell__title overlay__item overlay__item--right-middle">
                    {row.name}
                </span>
                <span style={{ width: '250px' }} className="table__cell table__cell__email table__cell--prefixed">
                    {companyTypeToString(row.type)}
                </span>

                <GridMenuCell>
                    <GridMenuActionLink onClick={() => impersonate(row.id)} translationKey="Impersonate"></GridMenuActionLink>
                </GridMenuCell>
            </>
        )
    }

    const columns = [
        {
            key: 'Name',
            labelKey: 'Company Name',
            cssClass: 'table__header__item__name',
        },
        {
            key: 'CompanyType',
            labelKey: 'Type',
        },
        {
            cssClass: 'table__header__item__menu',
        },
    ]

    return (
        <main className="main">
            <BreadcrumbsItem to="/companies">Template Companies</BreadcrumbsItem>
            <div className="main__header">
                <div className="main-title">{t('Template Companies')}</div>

                <div className="main__header-buttons">
                    <MobileSidebarOpener iconClass="icon__search" />
                </div>
            </div>

            <Grid
                emptyMessageKey={'No tempalte companies found'}
                emptySearchMessageKey={'No tempalte companies found'}
                translationKey={'Template Companies List'}
                sidebarComponent={null}
                defaultPageSize={5}
                rowsPromiseFactory={(config: TableParams) => {
                    const filter = config.filter || []

                    filter.push({
                        property: 'CompanyType',
                        value: '1',
                        operator: 'Equal',
                    })

                    return ApiClient.post<GridData<CompanyRow>>('api/admin/getCompaniesList', {
                        projectionName: 'AdminCompaniesGridModel',
                        page: config.pageNumber,
                        count: config.pageSize,
                        filters: filter,
                    })
                }}
                rowComponent={RowComponent}
                tableSchema={{ columns }}></Grid>
        </main>
    )
}
