import React from 'react'
import { useGridActions } from '../grid/GridContextProvider'

export const MobileSidebarOpener = ({ containerClass, iconClass }: { containerClass?: string; iconClass?: string }) => {
    const ga = useGridActions()

    return (
        <div className={`button-container grid-search-opener-button ${containerClass}`}>
            <button className="button button--blue table__button" onClick={ga.toggleFiltersModal}>
                <span className={`icon ${iconClass}`}></span>
            </button>
        </div>
    )
}
