import { useMediaQuery } from 'react-responsive'

/**
 * Returns true if it's a mobile sized screen.
 *
 * For now it's set to 620px width, this mostly affects overlays and modals.
 */
export const useIsMobile = () : boolean => {
  const isMobile = useMediaQuery({
    query: '(max-width: 620px)'
  });

  return isMobile;
}
