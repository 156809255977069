import React, { useState, useContext } from 'react'
import { GridActions } from './Types'

const GridActionsUpdateContext = React.createContext((actions: GridActions) => {})

const GridActionsContext = React.createContext<GridActions>({
    refresh: () => {},
    toggleFiltersModal: () => {},
})

export const useGridActions = () => {
    return useContext(GridActionsContext)
}

export const useGridActionsUpdater = () => {
    return useContext(GridActionsUpdateContext)
}

export const GridContextProvider = (props: React.PropsWithChildren<any>) => {
    const [gridActions, setGridActions] = useState<GridActions>({
        refresh: () => {},
        toggleFiltersModal: () => {},
    })

    return (
        <GridActionsUpdateContext.Provider
            value={(actions: GridActions) => {
                setGridActions(actions)
            }}>
            <GridActionsContext.Provider value={gridActions}>{props.children}</GridActionsContext.Provider>
        </GridActionsUpdateContext.Provider>
    )
}
