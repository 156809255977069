import React, { useEffect, useMemo, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { GridMenuActionLink, GridMenuCell, GridMenuLink } from '../../shared/grid/GridMenu'
import { useGridActions } from '../../shared/grid/GridContextProvider'
import { toastSuccess, toastWarning } from '../../shared/toastr'
import { GridFilterForm, GridFilterInput } from '../../shared/grid/GridFilter'
import { Grid } from '../../shared/grid/Grid'
import { GridActions, GridData, TableParams } from '../../shared/grid/Types'
import { ApiClient } from '../../shared/api/api-client'
import { useOfferionModal } from '../../shared/modal/ConfirmationModal'
import { MobileSidebarOpener } from '../../shared/elements/MobileSidebarOpener'

interface CompanyRow {
    id: number
    name: string
    email: string
    address: string
    type: number
}

const companyTypeToString = (companyType: number) => {
    switch (companyType) {
        case 0:
            return 'Basic'

        case 1:
            return 'Lawyer'

        case 2:
            return 'Window Maker'

        case 3:
            return 'Renter'

        case 4:
            return 'Other'
    }
}

const Filter = ({ updateFilter }: { updateFilter: (filter: any[]) => void }) => {
    console.log("????")
    return (
        <GridFilterForm
            initialValues={{ name: '', email: '', promoCode: '', userFirstName: '', userLastName: '' }}
            onSubmit={v => {
                const filter: { property: string; operator: string; value: string }[] = []

                if (v.name) {
                    filter.push({
                        property: 'Name',
                        operator: 'Contains',
                        value: v.name,
                    })
                }

                if (v.email) {
                    filter.push({
                        property: 'Users.Email',
                        operator: 'ListElementContains',
                        value: v.email,
                    })
                }

                if (v.promoCode) {
                    filter.push({
                        property: 'PromoCode',
                        operator: 'Contains',
                        value: v.promoCode,
                    })
                }

                if (v.userFirstName) {
                    filter.push({
                        property: 'Users.FirstName',
                        operator: 'ListElementContains',
                        value: v.userFirstName,
                    })
                }

                if (v.userLastName) {
                    filter.push({
                        property: 'Users.LastName',
                        operator: 'ListElementContains',
                        value: v.userLastName,
                    })
                }

                updateFilter(filter)
            }}
            titleKey="Search"
            searchButtonLabelKey="Clients.Side.searchButton">
            <GridFilterInput name="name" placeholderKey="Name"></GridFilterInput>
            <GridFilterInput name="userFirstName" placeholderKey="User First Name"></GridFilterInput>
            <GridFilterInput name="userLastName" placeholderKey="User Last Name"></GridFilterInput>
            <GridFilterInput name="email" placeholderKey="User Email"></GridFilterInput>
            <GridFilterInput name="promoCode" placeholderKey="Promo Code"></GridFilterInput>
        </GridFilterForm>
    )
}

export const CompaniesListContainer = () => {
    const gridActions = useGridActions()
    const { t } = useTranslation()
    const offerionModal = useOfferionModal()

    const deleteCompany = (companyId: number, name: string) => {
        offerionModal.open('Delete company', 'Are you sure you want to delete ' + name, async () => {
            await ApiClient.delete(`/api/AdminCompany/DeleteCompany/${companyId}`)

            gridActions.refresh()

            toastSuccess('Company was succesfully deleted')
        })
    }

    const markAsTemplate = async (companyId: number, name: string, type: number) => {
        offerionModal.open(
            'Mark as template',
            `Are you sure you want to mark ${name} as template for ${companyTypeToString(type)}`,
            async () => {
                await ApiClient.post(`/api/AdminCompany/MarkAsTemplate/${companyId}`)

                gridActions.refresh()

                toastSuccess(`Company was succesfully marked as template for ${companyTypeToString(type)}`)
            }
        )
    }

    const impersonate = (companyId: number) => {
        ApiClient.post<string>('/api/impersonation/RequestAccessToken', {
            companyId: companyId,
        }).then(encryptedToken => {
            window.open(
                `${process.env.REACT_APP_SERVER_LOCATION}/impersonation?encryptedToken=${encodeURIComponent(encryptedToken)}`,
                '_blank'
            )
        })
    }

    const RowComponent = ({ row, gridActions }: { row: CompanyRow; gridActions: GridActions }) => {
        return (
            <>
                <span style={{ width: '300px' }} className="table__cell table__cell__title overlay__item overlay__item--right-middle">
                    {row.name}
                </span>
                <span style={{ width: '200px' }} className="table__cell table__cell--prefixed">
                    {row.address}
                </span>
                <span style={{ width: '200px' }} className="table__cell table__cell__email table__cell--prefixed">
                    {row.email}
                </span>
                <span style={{ width: '50px' }} className="table__cell table__cell__email table__cell--prefixed">
                    {companyTypeToString(row.type)}
                </span>
                <GridMenuCell>
                    <GridMenuActionLink onClick={() => impersonate(row.id)} translationKey="Impersonate"></GridMenuActionLink>
                    <GridMenuLink to={`/company/settings/${row.id}`} translationKey="Settings"></GridMenuLink>
                    <GridMenuActionLink
                        onClick={() => markAsTemplate(row.id, row.name, row.type)}
                        translationKey={`Mark as template for ${companyTypeToString(row.type)}`}></GridMenuActionLink>
                    <GridMenuActionLink onClick={() => deleteCompany(row.id, row.name)} translationKey="Delete"></GridMenuActionLink>
                </GridMenuCell>
            </>
        )
    }

    const columns = [
        {
            key: 'Name',
            labelKey: 'Company Name',
            cssClass: 'table__header__item__name',
        },
        {
            key: 'Name',
            labelKey: 'Company Address',
            cssClass: 'table__header__item__location',
        },
        {
            key: 'Name',
            labelKey: 'Email',
        },
        {
            key: 'CompanyType',
            labelKey: 'Type',
        },
        {
            cssClass: 'table__header__item__menu',
        },
    ]

    const rowsPromiseFactory = useMemo(() => {
        return (config: TableParams) => {
            const filter = config.filter || []

            filter.push({
                property: 'CompanyType',
                value: '0',
                operator: 'Equal',
            })

            return ApiClient.post<GridData<CompanyRow>>('api/admin/getCompaniesList', {
                projectionName: 'AdminCompaniesGridModel',
                page: config.pageNumber,
                count: config.pageSize,
                filters: filter,
            })
        }
    }, [])

    return (
        <main className="main">
            <BreadcrumbsItem to="/companies">Companies</BreadcrumbsItem>
            <div className="main__header">
                <div className="main-title">{t('Companies')}</div>

                <div className="main__header-buttons">
                    <MobileSidebarOpener iconClass="icon__search" />
                </div>
            </div>

            <Grid
                emptyMessageKey={'No companies found'}
                emptySearchMessageKey={'No companies found'}
                translationKey={'Companies List'}
                sidebarComponent={Filter}
                defaultPageSize={10}
                rowsPromiseFactory={rowsPromiseFactory}
                rowComponent={RowComponent}
                tableSchema={{ columns }}></Grid>
        </main>
    )
}
